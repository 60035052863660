import TextBanner from "../../components/Header/TextBanner"
import { Container } from "react-bootstrap"
import Preferences from "../../components/Preferences/Preferences"
import { API, KIND, BEARER } from "../../constants"
import Loader from "../../components/UI/Loader"
import { Fragment } from "react"
import { dataFormatted } from "../../utils/dataFormatted"
import { getJwt, getUserID } from "../../helpers"
import ModalError from "../../components/Modal/ModalError"
import { useFetch } from "../../hooks/useFetch"

/**
 * Display App when it is connected to the backend
 * @component
 * @author JP
 * @version 1.0
 */

function AppLogged() {
  //Get eventUserID and jwt
  const jwt = getJwt()
  const eventUserID = getUserID()

  //Set options for fetch
  const options = {
    headers: {
      Authorization: BEARER + jwt,
      "Content-Type": "application/json",
    },
  }

  //API Call
  const { isLoading, data, error } = useFetch(
    `${API}/attendee/${eventUserID}/preferences`,
    options
  )

  let preferenceID = null
  let value = "0"

  //Display Loader waiting data
  let displaySPA = <Loader />

  //Display error
  if (!isLoading && error !== "") {
    displaySPA = <ModalError show={true} error={error} />
  }

  //Display data if no error
  if (!isLoading && Object.keys(data).length > 0) {
    //Get event informations and format them
    const {
      eventName,
      eventPlace,
      eventStartDateFormatted,
      eventStartTimeFormatted,
      eventDatePhrase,
      nameWithFirstLetterUpper,
      attendeeLastName,
    } = dataFormatted(data)

    //Check if the attendee have already booked a preference
    const actualsPreferences =
      Object.keys(data.attendee.preferences).length !== 0
        ? data.attendee.preferences.restaurant
        : null

    if (actualsPreferences !== null) {
      const numberOfActualPreferences =
        data.attendee.preferences.restaurant.length
      if (numberOfActualPreferences > 0) {
        actualsPreferences.map((service) => {
          if (service.kind === `${KIND}`) {
            preferenceID = service.id
            value = service.value
          }
          return true
        })
      }
    }

    //Rendering after loading
    displaySPA = (
      <Fragment>
        <TextBanner
          name={nameWithFirstLetterUpper}
          date={eventStartDateFormatted}
          eventName={eventName}
          place={eventPlace}
          datePhrase={eventDatePhrase}
          time={eventStartTimeFormatted}
          lastname={attendeeLastName}
        />
        <Preferences preferenceID={preferenceID} value={value} />
      </Fragment>
    )
  }

  return (
    <Container className="Logged" fluid>
      <div className="container--main">{displaySPA}</div>
    </Container>
  )
}

export default AppLogged
