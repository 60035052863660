import { Alert } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

function ModalError(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.closeButton ? (
        <Alert className="m-0" key="warning" variant="warning">
          <Modal.Header className="border-0  p-0" closeButton></Modal.Header>
          {props.error}
        </Alert>
      ) : (
        <Alert className="m-0" key="warning" variant="warning">
          <Modal.Header className="border-0 p-0"></Modal.Header>
          {props.error}
        </Alert>
      )}
    </Modal>
  )
}

export default ModalError
