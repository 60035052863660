import { useState } from "react"
import { BEARER, API } from "../constants"
import { attendeeToken } from "../utils/attendeeToken"
import { setJwt, getJwt } from "../helpers"
import jwtDecode from "jwt-decode"

/**
 * Send data to backend function POST OR PUT ROUTE
 *
 * @param {string} url
 * @param {string} jwt
 * @param {object} requestBody
 * @param {string} method
 * @param {function} handleSetPreferencePostData to inform Preferences if there have been a request
 * @return {object} isloading, data, error, handleSubmit function to activate the request
 * @author JP
 * @version 1.0
 */

export function useAttendeeFetch(
  url,
  requestBody,
  method,
  handleSetPreferencePostData
) {
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState("")

  //Get jwt and exp date
  const jwt = getJwt()
  const exp = jwtDecode(jwt).exp
  const now = Math.ceil(Date.now() / 1000)
  const delay = 2
  const needToRefresh = exp < now + delay

  async function handleSubmit() {
    if (!url) return
    if (requestBody.value === "0") return
    setError("")
    setLoading(true)

    if (needToRefresh) {
      const token = attendeeToken()
      let requestBody = {
        token: `${token}`,
      }
      try {
        const response = await fetch(`${API}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        })

        //Create a new error if serveur response != 2xx and catch
        if (!response.ok) {
          switch (response.status) {
            case 401:
              setError(
                "Bonjour, ce lien n'est pas ou plus valide. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
              )
              break
            case 404:
              setError(
                "Bonjour, La ressource est introuvable. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
              )
              break
            default:
              setError(
                "Bonjour, une erreur s'est produite. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
              )
          }
        } else {
          const data = await response.json()
          setJwt(data.jwt)
        }
      } catch (error) {
        setError(
          "Bonjour, une erreur s'est produite. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
        )
      }
    }

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: BEARER + jwt,
        },
        body: JSON.stringify(requestBody),
      })

      const data = await response.json()

      //Create a new error if serveur response != 2xx and catch
      if (!response.ok) {
        switch (response.status) {
          case 401:
            setError(
              "Bonjour, La ressource est introuvable. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
            )
            break
          default:
            setError(
              "Bonjour, une erreur s'est produite. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
            )
        }
      } else {
        setData(data.message)
        handleSetPreferencePostData(data.preference_id, requestBody.value)
      }
    } catch (error) {
      setError(
        "Bonjour, une erreur s'est produite. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
      )
    } finally {
      setLoading(false)
    }
  }

  return { isLoading, data, error, handleSubmit }
}
