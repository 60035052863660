import { Card } from "react-bootstrap"
import { Check } from "react-bootstrap-icons"
import { Fragment } from "react"
import "./PreferencesCard.css"

function PreferencesCard(props) {
  const id = props.id.toString()

  return (
    <Fragment>
      <Card id={id} onClick={props.handleClick}>
        <Card.Img variant="top" alt="Meal" src={props.image} />
        <h2 className="mt-2">{props.title}</h2>
        <Card.Body className="pt-0 m-auto flex0">
          <Card.Text>{props.text1}</Card.Text>
          <Card.Text>&</Card.Text>
          <Card.Text>{props.text2}</Card.Text>
          <div
            className={
              id === props.preference ? "checked mt-2" : "label__checkbox mt-2"
            }
          >
            <span className="label__text">
              <span className="label__check mx-auto">
                <Check color="white" className="icon" />
              </span>
            </span>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  )
}

export default PreferencesCard
