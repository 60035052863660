import "./Button.css"
import { Oval } from "react-loader-spinner"
import { Check } from "react-bootstrap-icons"

function Button(props) {
  return (
    <div>
      <button
        disabled={props.disabled}
        onClick={props.onClick}
        className="Button fadeInUp"
      >
        {props.children}
        {props.isLoading ? (
          <Oval
            height={16}
            width={16}
            color="#ffffff"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#ffffff"
            strokeWidth={4}
            strokeWidthSecondary={4}
            className="spinner"
          />
        ) : (
          ""
        )}
        {props.isSend && !props.isLoading ? (
          <div>
            <Check size={24} />
          </div>
        ) : (
          ""
        )}
      </button>
    </div>
  )
}

export default Button
