import "./Loader.css"
import { Oval } from "react-loader-spinner"
import { Fragment } from "react"
import ReactDOM from "react-dom"

//Loader with backdrop
function BackdropSpinner(props) {
  return (
    <div className="loader">
      <Oval
        height={props.width}
        width={props.width}
        color="#ffffff"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#ffffff"
        strokeWidth={4}
        strokeWidthSecondary={4}
        className="spinner"
      />
    </div>
  )
}

//Portal create above root with ID Backdrop
function Loader(props) {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <BackdropSpinner width={props.width} />,
        document.getElementById("backdrop")
      )}
    </Fragment>
  )
}

export default Loader
