export const menus = [
  {
    id: "1",
    title: "Menu 1",
    text1:
      "Diot au vin rouge servi en sandwich avec une tranche de reblochon à l’intérieur et soupe de potimarron maison",
    text2: "Tarte aux pommes",
  },
  {
    id: "2",
    title: "Menu 2 (végétarien)",
    text1: "Reblochon servi en sandwich et soupe de potimarron maison",
    text2: "Tarte aux pommes",
  },
  {
    id: "3",
    title: "Menu 3",
    text1:
      "Tartiflette géante cuisinée à la poêle avec des pommes de terre fraîches coupées à la main, du reblochon AOP, des échalotes fraîches et un lard de qualité",
    text2: "Tarte aux pommes",
  },
  {
    id: "4",
    title: "Menu 4 (végétarien)",
    text1:
      "Tartiflette végétarienne cuisinée à la poêle avec des pommes de terre fraîches coupées à la main, du reblochon AOP et des échalotes fraîches",
    text2: "Tarte aux pommes",
  },
]
