export const getJwt = () => {
  return sessionStorage.getItem("JWT")
}

export const setJwt = (jwt) => {
  if (jwt) {
    sessionStorage.setItem("JWT", jwt)
  }
}

export const getUserID = () => {
  return sessionStorage.getItem("UserID")
}

export const setUserID = (id) => {
  if (id) {
    sessionStorage.setItem("UserID", id)
  }
}

export const getExpDate = () => {
  return sessionStorage.getItem("ExpDate")
}

export const setExpDate = (timestamp) => {
  if (timestamp) {
    sessionStorage.setItem("ExpDate", timestamp)
  }
}
