import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

function TextBanner(props) {
  return (
    <Container className="header">
      <h3 className="mb-3">
        Bonjour {props.name} {props.lastname}
      </h3>
      <div className="mb-1  text-justify">
        Veuillez trouver ci-dessous les menus proposés pour l'évènement{" "}
        <strong>"{props.eventName}"</strong> se déroulant {props.datePhrase} à{" "}
        {props.time} à l'adresse suivante : <strong>{props.place}</strong>
      </div>
      <div className="mb-1 text-left">
        Merci de sélectionner un menu pour cette occasion :
      </div>
    </Container>
  )
}

TextBanner.propTypes = {
  /**
   * Event informations and attendee name
   */
  name: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
}

export default TextBanner
