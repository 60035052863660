import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import { Container } from "react-bootstrap"
import Loader from "./components/UI/Loader"
import jwtDecode from "jwt-decode"
import AppLogged from "./pages/AppLogged/AppLogged"
import Logo from "./components/Header/Logo"
import Footer from "./components/Footer/Footer"
import ModalError from "./components/Modal/ModalError"
import { setJwt, setUserID, setExpDate } from "./helpers"
import { attendeeToken } from "./utils/attendeeToken"
import { API } from "./constants"
import { useFetch } from "./hooks/useFetch"

function App() {
  //Get attendee token from URL
  const token = attendeeToken()

  //Add token in the body request
  let requestBody = {
    token: `${token}`,
  }

  //Set options for fetch
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  }

  //API Call to get jwt
  const { isLoading, data, error } = useFetch(`${API}/login`, options)

  //Display Loader waiting data
  let displaySPA = <Loader />

  //Recover API data connexion
  if (!isLoading && Object.keys(data).length > 0) {
    setJwt(data.jwt)
    const jwt = data.jwt
    const decoded = jwtDecode(jwt)
    setUserID(decoded.event_user_id)
    setExpDate(decoded.exp)
    displaySPA = <AppLogged />
  }

  //Display error
  if (!isLoading && error !== "") {
    displaySPA = <ModalError show={true} error={error} />
  }

  return (
    <Container className="App" fluid>
      <Logo />
      <hr />
      <div className="mx-auto ">{displaySPA}</div>
      <Footer />
    </Container>
  )
}

export default App
