//Function to format datas from API
export function dataFormatted(data) {
  //Event
  const eventName = data.event.name
  const eventPlace = data.event.place
  const eventStartDate = new Date(data.event.start_at)
  const eventEndDate = new Date(data.event.end_at)
  let eventDatePhrase = ""

  const eventStartDateFormatted = eventStartDate.toLocaleDateString("fr-FR", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  })
  const eventStartDateFormattedMultipleDays = eventStartDate.toLocaleDateString(
    "fr-FR",
    {
      weekday: "long",
      day: "numeric",
      month: "long",
    }
  )
  const minutes =
    eventStartDate.getMinutes() === 0 ? "" : eventStartDate.getMinutes()
  const eventStartTimeFormatted = eventStartDate.getHours() + "h" + minutes

  const eventEndDateFormatted = eventEndDate.toLocaleDateString("fr-FR", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  })

  if (
    eventStartDate.getDay() === eventEndDate.getDay() &&
    eventStartDate.getMonth() === eventEndDate.getMonth() &&
    eventStartDate.getFullYear() === eventEndDate.getFullYear()
  ) {
    eventDatePhrase = `le ${eventStartDateFormatted}`
  } else {
    eventDatePhrase = `du ${eventStartDateFormattedMultipleDays} au ${eventEndDateFormatted}`
  }

  //Attendee
  const attendeeFirstName = data.attendee.firstname
  const nameWithFirstLetterUpper =
    attendeeFirstName.charAt(0).toUpperCase() + attendeeFirstName.slice(1)
  const attendeeLastName = data.attendee.lastname

  return {
    eventName,
    eventPlace,
    eventStartDateFormatted,
    eventStartTimeFormatted,
    eventDatePhrase,
    nameWithFirstLetterUpper,
    attendeeLastName,
  }
}
