import logo from "../../assets/app-logo.webp"
import "./Footer.css"

function Footer() {
  return (
    <div className="d-flex justify-content-center">
      {" "}
      <img
        className="img-fluid mx-2 logoBotanic position-relative"
        src={logo}
        alt="logo"
        width={500}
        height={500}
      />{" "}
      <div className="smaller-font my-2 text-light position-relative">
        TravelApp - Tous droits réservés - 2023
      </div>
    </div>
  )
}

export default Footer
