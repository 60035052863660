import { Alert } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

function ModalValidation(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Alert className="m-0" key="success" variant="success">
        <Modal.Header className="border-0 p-2" closeButton>
          Votre choix a bien été enregistré
        </Modal.Header>
      </Alert>
    </Modal>
  )
}

export default ModalValidation
