import { Row } from "react-bootstrap"
import logoBotanic from "../../assets/logoBotanic.png"
import "./Logo.css"

function Logo() {
  return (
    <Row className="logos mx-auto mt-4 text-right position-relative">
      <img
        className="img-fluid logoBotanic mb-1"
        src={logoBotanic}
        alt="logo"
        width={200}
        height={46}
      />
    </Row>
  )
}

export default Logo
