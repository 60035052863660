import { useState, useEffect } from "react"

/**
 * Get data to backend (With token in URL)
 *
 * @param {string} url
 * @return {object} isloading, data (jwt), error
 * @version 1.0
 */

export function useFetch(url, options) {
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    if (!url) return
    setLoading(true)
    async function fetchData() {
      try {
        const response = await fetch(url, options)

        //Promise response management. If serveur response > 299 => set front end error else set response json object data
        if (!response.ok) {
          switch (response.status) {
            case 401:
              setError(
                "Bonjour, ce lien n'est pas ou plus valide. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
              )
              break
            case 404:
              setError(
                "Bonjour, La ressource est introuvable. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
              )
              break
            default:
              setError(
                "Bonjour, une erreur s'est produite. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
              )
          }
        } else {
          const data = await response.json()
          setData(data)
        }
      } catch (error) {
        setError(
          "Bonjour, une erreur s'est produite. Merci de bien vouloir contacter si nécessaire la personne responsable du salon."
        )
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return { isLoading, data, error }
}
