import { API, KIND, SERVICE } from "../../constants"
import Button from "../UI/Button"
import { useState } from "react"
import ModalValidation from "../Modal/ModalValidation"
import "./Preferences.css"
import { getUserID } from "../../helpers"
import ModalError from "../Modal/ModalError"
import { useAttendeeFetch } from "../../hooks/useAttendeeFetch"
import Carrousel from "../Carrousel/Carrousel"

/**
 * Display Preferences Cards
 *
 * @component
 * @param {number} eventUserID
 * @param {number} preferenceID for PUT route
 * @param {string} value for PUT route
 * @component
 * @author JP
 * @version 1.0
 */

function Preferences(props) {
  const [preference, setPreference] = useState(props.value)
  const [preferenceSaved, setPreferenceSaved] = useState(props.value)
  const [preferenceID, setPreferenceID] = useState(props.preferenceID)
  const [disabledButton, setDisabledButton] = useState(true)
  const [isSend, setIsSend] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [modalErrorShow, setModalErrorShow] = useState(true)

  const handleClick = (e) => {
    setPreference(e.currentTarget.id)
    setIsSend(false)
    if (e.currentTarget.id === preferenceSaved) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }

  //Check if there have been some POST data to toggle to PUT route and disabled button with actual preference
  const handleSetPreferencePostData = (id, value) => {
    setPreferenceID(id)
    setPreferenceSaved(value)
    setDisabledButton(true)
    setIsSend(true)
    setModalShow(true)
  }

  const handleSubmitPreference = () => {
    handleSubmit()
    setModalErrorShow(true)
  }
  //Get eventUserID
  const eventUserID = getUserID()

  let requestBody = {}
  let url = ""
  let method = "POST"

  //Toggle PUT or POST route
  if (preferenceID === null) {
    requestBody = {
      service_type: `${SERVICE}`,
      kind: `${KIND}`,
      value: `${preference}`,
    }
    url = `${API}/attendee/${eventUserID}/preferences`
  } else {
    requestBody = {
      value: `${preference}`,
    }
    url = `${API}/attendee/${eventUserID}/preferences/${preferenceID}`
    method = "PUT"
  }

  //API call conditionnaly if the attendee has already booked (PUT) or not (POST)
  let { isLoading, error, handleSubmit } = useAttendeeFetch(
    url,
    requestBody,
    method,
    handleSetPreferencePostData
  )

  //Before API call displaying Preferences
  return (
    <div className="max-width-80">
      <Carrousel
        handleClick={handleClick}
        preference={preference}
        preferenceSaved={preferenceSaved}
      />
      <Button
        onClick={handleSubmitPreference}
        isLoading={isLoading}
        isSend={isSend}
        disabled={disabledButton}
        preference={preference}
      >
        {`${!isSend && !isLoading ? "Valider" : "Validé"}`}
      </Button>
      <ModalValidation show={modalShow} onHide={() => setModalShow(false)} />
      {error !== "" ? (
        <ModalError
          show={modalErrorShow}
          error={error}
          closeButton={true}
          onHide={() => setModalErrorShow(false)}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default Preferences
