import { v4 as uuidv4 } from "uuid"
import PreferencesCard from "../Preferences/PreferencesCard"
import { menus } from "../../utils/menus"
import imageMenu2 from "../../assets/tartif.png"
import imageMenu1 from "../../assets/diot.png"
import imageMenu3 from "../../assets/sandwich2.png"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Carrousel.css"

const pictures = [imageMenu1, imageMenu3, imageMenu2, imageMenu2]

function Carroussel(props) {
  const ininitialSlidePosition =
    props.preferenceSaved === "0" ? "1" : props.preferenceSaved

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    dots: true,
    initialSlide: ininitialSlidePosition - 1,
  }
  return (
    <Container className="max-width-800">
      <Slider {...settings}>
        {menus.map((menu, i) => (
          <div key={uuidv4()}>
            <PreferencesCard
              id={menu.id}
              title={menu.title}
              image={pictures[i]}
              text1={menu.text1}
              text2={menu.text2}
              handleClick={props.handleClick}
              preference={props.preference}
              preferenceSaved={props.preferenceSaved}
            />
          </div>
        ))}
      </Slider>
    </Container>
  )
}

export default Carroussel
