function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}

//Get attendee token from URL
export function attendeeToken() {
  const url = new URL(window.location)
  if (isValidUrl(url)) {
    const path = url.pathname
    const token = path.substring(path.lastIndexOf("/") + 1)
    return token
  } else {
    return null
  }
}
